// 第一步引入插件(本质是一个构造函数)
import VueRouter from 'vue-router'

// 第二步创建router实例对象并暴露
const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: "/home"
    },
    {
      path: '/home',
      component: () => import('@/views/home'),
    },
    {
      path: '/labelPage',
      name: "labelPage",
      component: () => import('@/views/labelPage'),
    },
    {
      path: "/goodsDetails",
      name: "goodsDetails",
      component: () => import('@/views/goodsDetails')
    },
    {
      path: "/shopping",
      name: "shopping",
      component: () => import('@/views/shopping')
    },
    {
      path: "/order_step1",
      name: "order_step1",
      component: () => import('@/views/order_step1')
    }
  ]
})

export default router