import Vue from 'vue'
import App from './App.vue'
// 引入router 插件
import VueRouter from 'vue-router'
// 使用插件
Vue.use(VueRouter)
import "@/assets/iconfont/iconfont.css"
import router from "./router";
import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
Vue.prototype.$api = api;
import css from 'swiper/css/swiper.min.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import api from './request/apis'
export default () => {
  Vue.use(VueAwesomeSwiper, {
    css
  })
}
Vue.config.productionTip = false;
new Vue({
  // 指定容器
  el: '#app',
  // 解析App模板
  render: h => h(App),
  // 使用路由
  router
})