<template>
  <div id="app">
    <router-view></router-view>

    <right_fun></right_fun>
  </div>
</template>

<script>
import right_fun from './components/right_fun.vue'
export default {
  name: 'App',
  components: {
    right_fun
  }
}
</script>

<style>
@import url("assets/css/index.css");
</style>
