<template>
  <div class="fun">
    <div class="list">
      <div class="icon">
        <span class="el-icon-chat-dot-round"></span>
      </div>
      <div class="html">
        <div class="count">
          <div>公告</div>
          <div>这是公告啊仔细地看看好啊后下德哈卡就看撒叫大家看就的卡接口就看大家空间空间打开就空间的卡</div>
        </div>
      </div>
    </div>

    <div class="list">
      <div class="icon">
        <span class="iconfont icon-dianhuatianchong"></span>
      </div>
      <div class="html">
        <div class="mask">
          <div class="qq list">
            <i class="iconfont icon-QQ"></i> 15753173023
          </div>

          <div class="phone list">
            <i class="iconfont icon-dianhua"></i> 15753173023
          </div>

          <div style="display: flex;justify-content: center;">
            <img class="icon" src="https://www.colortee.com/49dbbac0683168b38180ab7d8a352d2e.jpg" />
          </div>

          <div class="wx list">
            <i class="iconfont icon-weixin"></i> 微信咨询
          </div>
        </div>
      </div>
    </div>

    <div class="list">
      <div class="icon">
        <span class="iconfont icon-erweima"></span>
      </div>
      <div class="html">
        <div class="mask">
          <div style="display: flex;justify-content: center;">
            <img class="icon" src="https://www.colortee.com/49dbbac0683168b38180ab7d8a352d2e.jpg" />
          </div>
          <div class="list">公众号咨询</div>

          <div style="display: flex;justify-content: center;">
            <img class="icon" src="https://www.colortee.com/49dbbac0683168b38180ab7d8a352d2e.jpg" />
          </div>
          <div class="list">小程序下单</div>
        </div>
      </div>
    </div>

    <div class="list">
      <div class="icon" @click="up">
        <span class="iconfont icon-triangle-up"></span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "right_fun",
  data() {
    return {
    }
  },
  methods: {
    up() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth' // 平滑滚动
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.fun {
  position: fixed;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 55;
  .list {
    position: relative;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    margin-bottom: 5px;
    cursor: pointer;
    .icon {
      text-align: center;
      line-height: 50px;
      font-size: 30px;
      color: #fff;
      span {
        font-size: 30px;
      }
    }
    .html {
      position: absolute;
      right: 55px;
      width: 200px;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      top: 0;
      padding: 10px;
      font-size: 13px;
      z-index: 120;
      display: none;

      .mask {
        padding: 5px 10px;
        cursor: pointer;
        .list {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          height: 30px;
          text-align: center;
          i {
            font-size: 22px;
            margin-right: 10px;
            color: #b4b4b4;
          }
        }
        .list:hover {
          color: #03a9f4;
          i {
            color: #03a9f4;
          }
        }
        .wx {
          color: #78de5b;
          i {
            color: #78de5b;
          }
        }
        .icon {
          width: 130px;
          height: 130px;
          margin: auto;
        }
      }
    }
  }
  .top {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #fff;
    div {
      width: 100%;
      text-align: center;
    }
  }
  .list:hover {
    .html {
      display: block;
    }
  }
}
</style>